const leaflets = {
    "iperbimbo": {
        "config": {
            "primary-color": "#5ac5f3",
            "secondary-color": "#e30713",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/clientLogo_iperbimbo.png"),
            "serverPath": "iperbimbo2.volantinointerattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Volantino Interattivo di Iperbimbo!",
            "shareFlyerURL": "https://iperbimbo4.volantinointerattivo.net?v=648",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Iperbimbo | Volantino Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nuovo volantino di Iperbimbo. Offerte valide fino al 31 dicembre.",
            "ogImageMeta": "https://iperbimbo4.volantinointerattivo.net/media/images/fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-62",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 10,
            "client_id": 7,
            "signboard_id": 153,
            "has_pages": true,
            "hide_grocery_list": true,
            "hide_searchbar": true,
            "line_through": false,
            "category_banner": "",
            "category_banner_mobile": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "no_link_to_site": true,
            "large_video_icon": true,
            "marker_product_in_list": true,
            "marker": "circle_black",
            "version": 643
        },
        "leaflet": {
            "name": "Iperbimbo dicembre Gerenzano",
            "code": "648",
            "id_campaign": "648",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/Indice-Iperbimbo-Gerenzano_1dipMNO.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 23.008506723055326,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 5.7367919807589836
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 28.74529870381431,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 6.109667317718846
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 34.85496602153315,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 5.7367919807589836
                        }
                    },
                    {
                        "page": 11,
                        "blueprint": {
                            "top": 40.591758002292146,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 6.175848654581851
                        }
                    },
                    {
                        "page": 13,
                        "blueprint": {
                            "top": 46.767606656873994,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 5.7367919807589836
                        }
                    },
                    {
                        "page": 14,
                        "blueprint": {
                            "top": 52.504398637632974,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 6.175848654581851
                        }
                    },
                    {
                        "page": 16,
                        "blueprint": {
                            "top": 58.68024729221483,
                            "left": 6.328038826384291,
                            "width": 87.61526582836973,
                            "height": 6.133880001937012
                        }
                    }
                ]
            },
            "categories": [],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_1_neDbiqo.jpg",
                    "products": []
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_2_rZiMgQw.jpg",
                    "products": []
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_3_CiJt4Hk.jpg",
                    "products": []
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_4_S1Q4cZu.jpg",
                    "products": []
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_5_ygNuflV.jpg",
                    "products": []
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_6_Io6qsMk.jpg",
                    "products": []
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_7_cb9BZAk.jpg",
                    "products": []
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_8_FUaxNMK.jpg",
                    "products": []
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_9_pUKGlNw.jpg",
                    "products": []
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_10_rF9700l.jpg",
                    "products": []
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_11_7G7X9rC.jpg",
                    "products": []
                },
                {
                    "number": 12,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_12_gFuCfON.jpg",
                    "products": []
                },
                {
                    "number": 13,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_13_FzcUsX8.jpg",
                    "products": []
                },
                {
                    "number": 14,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_14_byQgvcB.jpg",
                    "products": []
                },
                {
                    "number": 15,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_15_uEZfdDD.jpg",
                    "products": []
                },
                {
                    "number": 16,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/page_16_jUPKs5T.jpg",
                    "products": []
                },
                {
                    "number": 17,
                    "image_file": "https://interattivo.s3.amazonaws.com/dNx1ZwIDuJ/pages/Fine-Iperbimbo-21_merkx8J_XFB3yuZ.png",
                    "products": []
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.iperbimbo;
}

export const serverResponse = lefletSwitch();